import React from "react";
import Div from "../Div";

const Map = () => {
  return (
    <Div className="cs-google_map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.177906416681!2d90.40690778702421!3d23.776678332737916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c75e6c1ece3f%3A0xdd3d43dd5dd74815!2sWee%20Twist!5e0!3m2!1sbn!2sbd!4v1687846838339!5m2!1sbn!2sbd"
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
        title="Google Map"
      ></iframe>
      <img
        style={{ height: "34px", width: "auto" }}
        src={"/images/map.svg"}
        // src="https://maps.google.com/mapfiles/ms/icons/red-dot.png"
        alt="Red Marker"
        className="custom-marker"
      />
    </Div>
  );
};

export default Map;
