import React, { useState, useEffect } from 'react';
import Div from '../Div';

export default function VideoModal({ videoSrc, bgUrl, variant ,media_files}) {
  const apiKey = process.env.REACT_APP_YOUTUBE_API_KEY;

  const [iframeSrc, setIframeSrc] = useState('about:blank');
  const [toggle, setToggle] = useState(false);
  const [isHovered, setIsHovered] = useState(false)

  const playButtonStyle = {
    display: isHovered ? 'flex' : 'none',
  };

  const handelClick = () => {
  console.log('videoSrc,media_files', videoSrc,media_files)
    const video = videoSrc ? videoSrc.split('?v=')[1].trim() : '';
    setIframeSrc(`https://www.youtube.com/embed/${video}`);
    setToggle(!toggle);
  };
  const handelClose = () => {
    setIframeSrc('about:blank');
    setToggle(!toggle);
  };

  const [thumbnailUrl, setThumbnailUrl] = useState('');

  useEffect(() => {
    const getYouTubeThumbnail = async () => {
      try {
        const videoId = videoSrc.match(/(?<=v=|youtu.be\/|v\/|u\/\w\/|embed\/|v=|v\/|u\/\w\/|embed\/|youtu.be\/|watch\?v=|watch\?feature=player_embedded&v=|%2Fvideos%2F|embed%\u200C\u200B2F|youtu.be%2F|v=|u\/\w\/|embed\/|youtu.be\/|v\/|u\/\w\/|embed\/|youtu.be%2F|%2Fvideos%2F|embed%\u200C\u200B2F|%2Fvi%2F|watch\?v=|embed\/|youtu.be\/|v\/|u\/\w\/|embed\/|youtu.be%2F|%2Fvideos%2F|embed%\u200C\u200B2F|%2Fvi%2F)([^#\&\?\n]+)/);
    
        if (!videoId) {
          throw new Error('Invalid YouTube video link');
        }
    
        const response = await fetch(`https://www.googleapis.com/youtube/v3/videos?id=${videoId[0]}&key=${apiKey}&part=snippet`);
        const data = await response.json();
    
        if (data.items.length === 0) {
          throw new Error('No video found for the provided link');
        }
    
        const thumbnailUrl = data.items[0].snippet.thumbnails.maxres.url;
        setThumbnailUrl(thumbnailUrl);
      } catch (error) {
        console.error('Error fetching YouTube video thumbnail:', error);
      }
    };

    getYouTubeThumbnail();
  }, [videoSrc]);
  console.log('thumbnailUrl',thumbnailUrl)
  
  return (
    <>
      <Div
        className={`cs-video_block ${
          variant ? variant : 'cs-style1'
        } cs-video_open cs-bg`}
        style={{ backgroundImage: `url(${thumbnailUrl ? thumbnailUrl : "/images/no-thumbnail.webp"})` }}
        onClick={handelClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <span className="cs-player_btn cs-accent_color" style={playButtonStyle}>
          <span />
        </span>
      </Div>
      <Div className={toggle ? 'cs-video_popup active' : 'cs-video_popup'}>
        <Div className="cs-video_popup_overlay" />
        <Div className="cs-video_popup_content">
          <Div className="cs-video_popup_layer" />
          <Div className="cs-video_popup_container">
            <Div className="cs-video_popup_align">
              <Div className="embed-responsive embed-responsive-16by9">
                <iframe
                  className="embed-responsive-item"
                  src={iframeSrc}
                  title="video modal"
                />
              </Div>
            </Div>
            <Div className="cs-video_popup_close" onClick={handelClose} />
          </Div>
        </Div>
      </Div>
    </>
  );
}
