import React, { useState, useEffect } from "react";
import { pageTitle, BASE_URL } from "../../helper";
import Cta from "../Cta";
import PageHeading from "../PageHeading";
import Div from "../Div";
import Spacing from "../Spacing";
import axiosInstance from "../../helper/axiosInterceptor";

export default function ProducerDetails() {
  pageTitle("Producers");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [directorsData, setDirectorsData] = useState([]);

  // useEffect(() => {
  //   axiosInstance(`${BASE_URL}/api/team/`, {
  //     method: "GET",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //   }).then((response) => {
  //     const data = response.data.toReversed();
  //     setDirectorsData(data);
  //     console.log(data);
  //   });
  // }, []);
  // console.log(directorsData.toReversed(), "ddd")

  const [teamData, setTeamData] = useState([]);
  useEffect(() => {
    axiosInstance(`${BASE_URL}/api/team/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      const data = res.data?.map((item) => ({
        memberName: item.name,
        memberImage: item.photo,
        memberDesignation: item.designation,
        memberDescription: item.about,
      }));
      console.log(data);
      setTeamData(data);
    });
  }, []);
  const formatNameProperCase = (memberName) => {
    return memberName
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  };
  return (
    <>
      <PageHeading
        title="Producers"
        bgSrc="/images/about_hero_bg.webp"
        pageLinkText="Producers"
      />
      <Spacing lg="150" md="80" />
      {teamData
        .filter(
          (item) =>
            item.memberDesignation?.toLowerCase() === "executive producer" ||
            item.memberDesignation?.toLowerCase() === "line producer"
        )
        .map(
          ({
            memberImage,
            memberName,
            memberDesignation,
            memberDescription,
          }) => (
            <>
              <Div className="container">
                <Div className="row align-items-center">
                  <Div className="col-xl-5 col-lg-6">
                    <Div className="cs-radius_15 cs-shine_hover_1">
                      <img src={memberImage} alt="Member" className="w-100" />
                    </Div>
                  </Div>
                  <Div className="col-lg-6 offset-xl-1">
                    <Spacing lg="0" md="45" />
                    <Div className="cs-section_heading cs-style1">
                      <h2 className="cs-section_title">
                        {memberName && formatNameProperCase(memberName)}
                      </h2>
                      <Div className="cs-height_10 cs-height_lg_10" />
                      <h3
                        className="cs-section_subtitle"
                        style={{
                          color: "#ee364e",
                        }}
                      >
                        {memberDesignation}
                      </h3>
                      <Div className="cs-height_5 cs-height_lg_5" />
                      <Div className="cs-separator cs-accent_bg" />
                      <Div className="cs-height_45 cs-height_lg_25" />
                      <p className="cs-m0">{memberDescription}</p>

                      <Div className="cs-height_45 cs-height_lg_30" />
                      {/* <SocialWidget/> */}
                    </Div>
                  </Div>
                </Div>
              </Div>
              <Spacing lg="150" md="80" />
            </>
          )
        )}

      <Div className="container">
        <Cta
          title="Let’s discuss & make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.webp"
        />
      </Div>
    </>
  );
}
