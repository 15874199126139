import React from "react";
import Div from "../Div";
import FullScreenHorizontalSlider from "../Slider/FullScreenHorizontalSlider";
import VerticalLinks from "../VerticalLinks";

function VideoComponent({ src }) {
  return (
    <video
      src={src}
      className="nectar-video-bg"
      preload="auto"
      loop
      autoPlay
      playsInline
      muted
      // width="1800"
      // height="700"
      style={{
        visibility: "visible",
        width: "100%",
        maxHeight: "100%",
        opacity: 1,
        objectFit: "cover",
      }}
    >
      <source type="video/mp4" src={src} />
    </video>
  );
}

export default function Hero6({
  socialLinksHeading,
  heroSocialLinks,
  showcaseData,
  mediaType,
}) {
  console.log('mediaType', mediaType, showcaseData)
  return (
    <Div className="cs-video_6_wrap cs-hero_6_wrap">
     
      <VerticalLinks data={heroSocialLinks} title={socialLinksHeading} />
      {mediaType === "photo" ? (
        <FullScreenHorizontalSlider data={showcaseData} />
      ) : (
        <VideoComponent src={showcaseData.src} />
      )}
      {/* <video
        className="nectar-video-bg"
        preload="auto"
        loop
        autoPlay
        muted
        playsInline
        style={{
          visibility: "visible",
          width: "100%",
          maxHeight: "100%",
          opacity: 1,
          objectFit: "cover",
        }}
      >
        <source src="/images/videos/hero-video.mp4" type="video/mp4" />
      </video> */}
    </Div>
  );
}
