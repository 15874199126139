import React, { useEffect, useState } from 'react';
import axios from 'axios';
import axiosInstance from '../../helper/axiosInterceptor';
import { pageTitle, BASE_URL } from '../../helper';
import 'swiper/css';
import 'swiper/css/pagination';
import Hero6 from '../Hero/Hero6';


export default function ShowcasePortfolioHome() {
  // pageTitle('Showcase Portfolio');
  const [showcaseData, setShowCaseData] = useState([]);
  const [mediaType, setMediaType] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axiosInstance(`${BASE_URL}/api/home/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(res => {
        console.log(res.data)
        let data;
        if (res.data[0]?.media_type == "photo") {
          data = res.data?.map(item => ({
            title: item.title,
            imgUrl: item.media_files,
            href: '#',
          }));
        } else {
          data = {
            src: res.data[0]?.media_files
          }
        }
    
        setShowCaseData(data)
        setMediaType(res.data[0]?.media_type)
      })
  }, []);

  const heroSocialLinks = [
    {
      name: 'Facebook',
      links: 'https://www.facebook.com/WeeTwistInc',
    },
    {
      name: 'Youtube',
      links: 'https://www.youtube.com/@weetwist',
    },
  ];

  return (
    <Hero6
      heroSocialLinks={heroSocialLinks}
      socialLinksHeading="Follow Us"
      showcaseData={showcaseData}
      mediaType={mediaType}
    />
  );
}
