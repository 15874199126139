import React, { useEffect, useState } from "react";
import axiosInstance from "../../helper/axiosInterceptor";
import Div from "../Div";
import Slider from "react-slick";
import { BASE_URL } from "../../helper";
import Clients from "../Clients";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import Card from "../Card";

export default function ClientSlider() {
  const [clientData, setClientData] = useState([]);

  useEffect(() => {
    axiosInstance(`${BASE_URL}/api/partners/`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      let data = response.data?.map((item) => ({
        title: item.name,
        src: item.image,
      }));
      if (data.length === 1) {
        data = [...data, ...data];
      }
      setClientData(data);
    });
  }, []);

  /** Slider Settings **/

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    
    arrows: false,
    slidesToShow: clientData?.length > 9 ? 10 : clientData?.length || 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 7,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 5,
        },
      },
      {
        breakpoint: 468,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  return (
    <>
      {clientData && clientData.length > 0 && (
        <>
          <Div className="container">
            <SectionHeading
              title="BRAND x LOVE"
              subtitle="Our Clients"
              variant="cs-style1 text-center"
              styles={{ color: "#ee364e" }}
            />
            <Spacing lg="90" md="45" />
          </Div>
          <div className="client-carousel container">
            <Slider {...settings} className="cs-slider cs-style3 cs-gap-12">
              {clientData.map((item, index) => (
                <Div key={index}>
                  <Clients
                    title={item.title}
                    subtitle={item.subtitle}
                    href="#"
                    src={item.src}
                  />
                </Div>
              ))}
            </Slider>
            {/* <div className="row">
              {clientData.map((item) => (
                <Div className="col-md-2">
                  <Card
                    title=""
                    link=""
                    src={item.src}
                    alt="client"
                    ServiceDetails=""
                  />
                </Div>
              ))}
            </div> */}
          </div>
          <Spacing lg="150" md="80" />
        </>
      )}
    </>
  );
}
