import React, { useState } from 'react'
import Div from '../Div'
import SocialWidget from './SocialWidget';

export default function Newsletter({title, subtitle, placeholder}) {
  const [email, setEmail] = useState('');

  const handleFormSubmit = (event) => {
    event.preventDefault();
    console.log('Submitted Email:', email);
    setEmail('');
  };

  const handleInputChange = (event) => {
    const { value } = event.target;
    setEmail(value);
  };
  return (
    <>
      {title && <h2 className="cs-widget_title">{title}</h2>}
      <Div className="cs-newsletter cs-style1">
        {/* <form onSubmit={handleFormSubmit} className="cs-newsletter_form">
          <input type="email" name='email' onChange={handleInputChange} className="cs-newsletter_input" placeholder={placeholder} />
          <button className="cs-newsletter_btn"><span>Send</span></button>
        </form> */}
          <SocialWidget />
        <Div className="cs-newsletter_text">{subtitle}</Div>
      </Div>
    </>
  )
}
