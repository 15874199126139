import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget() {
  return (
    <Div className="cs-social_btns cs-style1">
      <Link to='https://vimeo.com/weetwist ' className="cs-center" style={{
        borderColor: "#ee364e",
        // color: "#ee364e"
      }}>
        <Icon icon="fa6-brands:vimeo" />
      </Link>
      <Link to='https://www.instagram.com/weetwist/?fbclid=IwAR2yMb5rBeaAiAVIgrUlg6eS_lTnDhmVVujEgSME5GMLWF3wYzlIlZ4QMeQ' className="cs-center" style={{
        borderColor: "#ee364e",
        // color: "#ee364e"
      }}>
        <Icon icon="fa6-brands:instagram" />               
      </Link>
      <Link to='https://www.youtube.com/@weetwist' className="cs-center" style={{
        borderColor: "#ee364e",
        // color: "#ee364e"
      }}>
        <Icon icon="fa6-brands:youtube" />              
      </Link>
      <Link to='https://www.facebook.com/WeeTwistInc' className="cs-center" style={{
        borderColor: "#ee364e",
        // color: "#ee364e"
      }}>
        <Icon icon="fa6-brands:facebook" />
      </Link>
    </Div>
  )
}
