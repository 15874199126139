import React from "react";
import { Link } from "react-router-dom";
import Div from "../Div";
import "./clients.scss";

export default function Clients({ href, src, title, subtitle, variant }) {
  return (
    <Link
      to={href}
      className={`cs-client cs-bg ${variant ? variant : "cs-style1"}`}
    >
      <>
        <Div className="cs-client_hover" />
        <Div
          className="cs-client_bg cs-bg" 
          style={{ backgroundImage: `url("${src}")`, borderRadius:0 , backgroundSize:"contain"}}
        />
       
      </>
    </Link>
  );
}
