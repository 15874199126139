import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import axios from "axios";
import { pageTitle, BASE_URL } from "../../helper";
import Div from "../Div";
import PageHeading from "../PageHeading";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import Cta from "../Cta";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Map from "../map/Map";

export default function ContactPage() {
  pageTitle("Contact Us");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    project_type: "",
    mobile: "",
    message: "",
  });
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    attested: [],
    attested_type: "",
    mobile: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const submitContact = (formData) => {
    const url = `${BASE_URL}/api/email/`;
    axios
      .post(url, formData)
      .then((res) => {
        console.log(res);
        toast.success("Message sent!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        console.error(err);
        toast.error("Message not sent!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();

    if (validateForm()) {
      submitContact(formData);
      setFormData({
        name: "",
        email: "",
        project_type: "",
        mobile: "",
        message: "",
      });
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (!formData.name) {
      setNameError("Please enter your name");
      isValid = false;
    } else {
      setNameError("");
    }

    if (!formData.email) {
      setEmailError("Please enter your email");
      isValid = false;
    } else {
      setEmailError("");
    }

    return isValid;
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const submitSendUsForm = (formData) => {
    console.log(formData, "xxx");
    const url = `${BASE_URL}/api/sendus/`;
    axios
      .post(url, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        console.log(res);
        toast.success("Message sent!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      })
      .catch((err) => {
        console.error(err);
        toast.error("Message not sent!", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      });
  };

  const MAX_FILE_SIZE = 15 * 1024 * 1024; // 10 MB

  const [fileSizeError, setFileSizeError] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file && file.size <= MAX_FILE_SIZE) {
      setFormValues((prevFormValues) => ({
        ...prevFormValues,
        attested: file,
      }));
      setFileSizeError(false); // Reset the error state
    } else {
      setFileSizeError(true); // Set the error state to true
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    submitSendUsForm(formValues);

    setFormValues({
      name: "",
      email: "",
      attested: null,
      attested_type: "",
      mobile: "",
    });
  };

  return (
    <>
      <PageHeading
        title="Contact Us"
        bgSrc="/images/contact_hero_bg.webp"
        pageLinkText="Contact Us"
      />
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Need a kick-ass creative production partner?"
              subtitle="Get In Touch"
            />
            <Spacing lg="55" md="30" />
            <ContactInfoWidget withIcon />
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form onSubmit={handleFormSubmit} className="row">
              <Div className="col-sm-6">
                <label className="cs-primary_color">Full Name</label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  className="cs-form_field"
                  required
                />
                <span className="error-message">{nameError}</span>
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  className="cs-form_field"
                  required
                />
                <span className="error-message">{emailError}</span>
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Project Type</label>
                <input
                  type="text"
                  name="project_type"
                  value={formData.project_type}
                  onChange={handleInputChange}
                  className="cs-form_field"
                  required
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Mobile</label>
                <input
                  type="text"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleInputChange}
                  className="cs-form_field"
                  required
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Message</label>
                <textarea
                  cols="30"
                  rows="7"
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  className="cs-form_field"
                  required
                ></textarea>
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button type="submit" className="cs-btn cs-style1">
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="container">
        {/* <Cta
          title=""
          // btnText="Apply For Meeting"
          // btnLink="/contact"
          bgSrc="/images/hero-bg-2.webp"
        /> */}
        <img
          style={{ filter: "brightness(0.7)" }}
         
         src="/images/bg-img.gif"
          // src="/images/hero-bg-2.webp"
          alt=""
        />
      </Div>
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-lg-6">
            <SectionHeading
              title="Want to work <br/>with us?"
              subtitle="Send Us"
            />
            <Spacing lg="55" md="30" />
            {/* <ContactInfoWidget withIcon /> */}
            <Spacing lg="0" md="50" />
          </Div>
          <Div className="col-lg-6">
            <form onSubmit={handleSubmit} className="row">
              <Div className="col-sm-6">
                <label className="cs-primary_color">Full Name</label>
                <input
                  type="text"
                  name="name"
                  value={formValues.name}
                  onChange={handleChange}
                  className="cs-form_field"
                  required
                />
                <span className="error-message">{nameError}</span>
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Email</label>
                <input
                  type="email"
                  name="email"
                  value={formValues.email}
                  onChange={handleChange}
                  className="cs-form_field"
                  required
                />
                <span className="error-message">{emailError}</span>
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Mobile</label>
                <input
                  type="text"
                  name="mobile"
                  value={formValues.mobile}
                  onChange={handleChange}
                  className="cs-form_field"
                  required
                />
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-6">
                <label className="cs-primary_color">Attachment Type</label>
                <select
                  // type="text"
                  name="attested_type"
                  value={formValues.attested_type}
                  onChange={handleChange}
                  className="cs-form_field"
                  required
                >
                  <option style={{ backgroundColor: "#181818" }} value="">
                    Select Attachment Type
                  </option>
                  <option style={{ backgroundColor: "#181818" }} value="cv">
                    CV
                  </option>
                  <option
                    style={{ backgroundColor: "#181818" }}
                    value="photography"
                  >
                    Photography
                  </option>
                  <option style={{ backgroundColor: "#181818" }} value="story">
                    Story
                  </option>
                  <option style={{ backgroundColor: "#181818" }} value="video">
                    Video
                  </option>
                </select>
                <Spacing lg="20" md="20" />
              </Div>
              <Div className="col-sm-12">
                <label className="cs-primary_color">Attachment</label>
                <input
                  type="file"
                  name="attested"
                  onChange={handleFileChange}
                  className="cs-form_field"
                  required
                />
                {fileSizeError && (
                  <p className="error-message" style={{ color: "red"}}>
                    File size exceeds the maximum limit (15 MB)
                  </p>
                )}
                <Spacing lg="25" md="25" />
              </Div>
              <Div className="col-sm-12">
                <button type="submit" className="cs-btn cs-style1">
                  <span>Send Message</span>
                  <Icon icon="bi:arrow-right" />
                </button>
              </Div>
            </form>
          </Div>
        </Div>
      </Div>
      <Spacing lg="150" md="80" />
      <Map/>
      {/* <Div className="cs-google_map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.177906416681!2d90.40690778702421!3d23.776678332737916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c75e6c1ece3f%3A0xdd3d43dd5dd74815!2sWee%20Twist!5e0!3m2!1sbn!2sbd!4v1687846838339!5m2!1sbn!2sbd"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map"
        ></iframe>
      </Div> */}
      <Spacing lg="50" md="40" />
      <ToastContainer />
    </>
  );
}
